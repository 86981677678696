import { $, body } from "@utils/dom";
import { on, off } from "@utils/listener";
import { moduleDelays } from "./utils";

const SELECTOR = "[data-site-loader]";

class SiteLoader {
  constructor() {
    this.el = $(SELECTOR);
    this.bg = $('.site-loader__bg', this.el);
    this.logo = $('.site-loader__logo', this.el);

    this.animatedIn = false;
    this.animateInCount = 0;
    this.animateOutCount = 0;
    this._readyPromise = null;

    this._animateInCompletedBnd = this._animateInCompleted.bind(this);
    this._animateOutCompletedBnd = this._animateOutCompleted.bind(this);
    this._destroyBnd = this._destroy.bind(this);
  }

  init(){
    this._animateIn();
  }
  loaded() {
    moduleDelays(350, 1200);
  }
  ready() {
    return new Promise((resolve) => {
      this._readyPromise = resolve;

      if (this.animatedIn) this._animateOut(); 
    });
  }

  _destroy(){
    // remove from DOM when completed
    if( this.el && this.el.parentNode ) this.el.parentNode.removeChild(this.el);

    // unbind events
    if( this.bg ) off(this.bg, 'transitionend', this._destroyBnd);
    if( this.logo ) {
      off(this.logo, 'transitionend', this._animateInCompletedBnd);
      off(this.logo, 'transitionend', this._animateOutCompletedBnd);
    }

    this.el = null;
    this.bg = null;
    this.logo = null;

    this.animatedIn = true;
    this.animateInCount = null;
    this.animateOutCount = null;
    this._readyPromise = null;

    this._animateInCompletedBnd = null;
    this._animateOutCompletedBnd = null;
    this._destroyBnd = null;
  }

  _animateIn() {
    on(this.logo, 'transitionend', this._animateInCompletedBnd);
    this.el.classList.add('--animate-in');
  }
  _animateInCompleted() {
    this.animateInCount++;
    if( this.animateInCount < 2 ) return;

    off(this.logo, 'transitionend', this._animateInCompletedBnd);

    this.animatedIn = true;
    if( this._readyPromise ) this._animateOut();
  }
  _animateOut() {
    on(this.logo, 'transitionend', this._animateOutCompletedBnd);
    on(this.bg, 'transitionend', this._destroyBnd);

    this.el.classList.add('--animate-out');
  }
  _animateOutCompleted() {
    this.animateOutCount++;
    if( this.animateOutCount < 3 ) return;

    off(this.logo, 'transitionend', this._animateOutCompletedBnd);

    body.classList.add("--js-ready");
    this._readyPromise();
    this._readyPromise = null;
  }
}

export default SiteLoader;
