import { Howl } from 'howler/dist/howler.core.min.js';

import track from '@audio/dark-woods.mp3';
import emitter from '@core/emitter';
import windmill from '@core/windmill';
import { $, $$ } from '@utils/dom';
import { on } from '@utils/listener';

const FADE_IN_DURATION = 2000;
const FADE_OUT_DURATION = 1000;
const PLAYING_CLASSNAME = "--js-playing";
const VOLUME = 1;
const STORAGE_NAMESPACE = "tl-33immortals-audio";

class Audio {
  constructor() {
    this.btns = null;
    this.sound = null;

    // Check user has stored preference
    this._enabled = localStorage.getItem(STORAGE_NAMESPACE) !== "false";
    this._loaded = false;
    this._playing = false;
    this._unlocked = false;
    this._tabActive = true;
    this._windmillReady = false;

    this._onWindmillReady = this._onWindmillReady.bind(this);
    this._onAudioPlay = this._onAudioPlay.bind(this);
    this._onAudioPause = this._onAudioPause.bind(this);
    this._onTabSwitch = this._onTabSwitch.bind(this);
    this._soundLoaded = this._soundLoaded.bind(this);
    this._soundUnlocked = this._soundUnlocked.bind(this);
    this._onSoundFaded = this._onSoundFaded.bind(this);
    this._onBtnClick = this._onBtnClick.bind(this);
  }

  init() {
    this.sound = new Howl({ src: [track], autoplay: false, loop: true, volume: VOLUME });
    this.btns = [ ...$$('.audio-btn') ].map(btn => new AudioBtn(btn));

    this._bindEvents();
  }
  play() {
    if( !this.sound ) return;

    if( this._tabActive && this._loaded && this._unlocked && this._enabled ) {
      if( this._playing ) return;
      this._playing = true;

      this.sound.play();
      if( this.sound.volume() !== VOLUME ) this.sound.fade(this.sound.volume(), VOLUME, FADE_IN_DURATION);

      this._updateButtons();
    }
  }
  pause() {
    if( !this.sound ) return;

    if( this._loaded && this._unlocked ) {
      if( !this._playing ) return;
      this._playing = false;

      this.sound.fade(this.sound.volume(), 0, FADE_OUT_DURATION);
      this._updateButtons();
    }
  }

  _bindEvents() {
    windmill.on('ready', this._onWindmillReady);
    emitter.on('Audio.play', this._onAudioPlay);
    emitter.on('Audio.pause', this._onAudioPause);
    on(document, 'visibilitychange', this._onTabSwitch);

    if( this.btns ) on(this.btns.map(btn => btn.el), 'click', this._onBtnClick);
    if( this.sound ) {
      this.sound.once('load', this._soundLoaded);
      this.sound.once('unlock', this._soundUnlocked);
      this.sound.on('fade', this._onSoundFaded);
    }
  }
  _updateButtons() {
    if( this.btns ) this.btns.forEach(btn => btn.active = this._playing);
  }

  _onWindmillReady() {
    this._windmillReady = true;
    this.play();
  }
  _onTabSwitch() {
    this._tabActive = document.visibilityState === "visible";
    if( !this._windmillReady ) return;

    if( this._tabActive ) this.play();
    else this.pause();
  }
  _onAudioPlay() { this.play(); }
  _onAudioPause() { this.pause(); }

  _soundLoaded() { this._loaded = true; }
  _soundUnlocked() {
    this._unlocked = true;
    this.play();
  }
  _onSoundFaded() {
    if( this.sound.volume() === 0 && this._playing === false ) this.sound.pause();
  }
  _onBtnClick(event) {
    if( event ) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }

    this.enabled = !this._enabled;
  }


  // getter - setter
  get enabled() { return this._enabled; }
  set enabled(value) {
    if( this._enabled === value ) return;
    this._enabled = value;

    // store preference in locale storage
    localStorage.setItem(STORAGE_NAMESPACE, this._enabled);

    if( !this._enabled ) this.pause();
    else this.play();
  }
}


class AudioBtn {
  constructor(el) {
    this.el = el;
    this.label = $('.audio-btn__label', this.el);

    this._active = false;
    this._labelOn = this.el.dataset.labelOn;
    this._labelOff = this.el.dataset.labelOff;
  }

  // getter - setter
  get active() { return this._active; }
  set active(value) {
    if( this._active === value ) return;
    this._active = value;

    const label = this._active ? this._labelOff : this._labelOn;

    this.label.textContent = label;
    this.label.setAttribute('aria-label', label);
    this.el.classList[this._active ? 'add' : 'remove'](PLAYING_CLASSNAME);
  }
}

const SINGLETON = new Audio();
export default SINGLETON;

